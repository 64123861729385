import { getLoginType } from "./auth";

function getCircularReplacer() {
  const seen = new WeakSet();
  return (key:any, value:any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return; // Remove circular references
      }
      seen.add(value);
    }
    return value;
  };
}
export class StateLoader {
  loadState(): any {
    try {
      const serializedState: string | null = localStorage.getItem(
        "files.lighthouse.storage/store"
      );
      if (serializedState === null) {
        return this.initializeState();
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state: any): void {
    try {
      if (state && typeof state === 'object' && 'file' in state) {
        delete state.file.filesArr;
      }
      if (state && typeof state === 'object' && 'currentProvider' in state) {
        delete state.currentProvider;
      }
        // switch (state.auth.loginType) {
        //   case 'openlogin':
        //     const web3authProvider = state?.["currentProvider"]?.['web3authProvider'];
        //     delete state.currentProvider;
        //     state.currentProvider = { web3authProvider };            
        //     break;
        //   case 'metamask':
        //     const metamaskProvider = state?.["currentProvider"]?.['metamaskProvider'];
        //     delete state.currentProvider;
        //     state.currentProvider = { metamaskProvider };            
        //     break;
        //   case "Coreum":
        //     const cosmoskitClient = state?.["currentProvider"]?.['cosmoskitClient'];
        //     delete state.currentProvider;
        //     state.currentProvider = { cosmoskitClient };            
        //     break;
        
        //   default:
        //     delete state.currentProvider;
        //     break;
        // }
      const serializedState: string = JSON.stringify(state);
      // const serializedState: string = JSON.stringify(state,getCircularReplacer());
      localStorage.setItem("files.lighthouse.storage/store", serializedState);
    } catch (err) {
      
    }
  }

  initializeState(): any {
    return {};
  }
}

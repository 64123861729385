const chainReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case "setCurrentChain":
      return action.payload;
    case "getCurrentChain":
      return state;
    default:
      return state;
  }
};

export default chainReducer;

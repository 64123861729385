import { combineReducers, Reducer } from "redux";
import fileReducer from "./fileReducer";
import authReducer from "./authReducer";
import otherDataReducer from "./otherDataReducer";
import balanceReducer from "./balanceReducer";
import topupReducer from "./topupReducer";
import chainReducer from "./chainReducer";
import providerReducer from "./providerReducer";

interface RootState {
  file: Reducer<any, any>;
  auth: Reducer<any, any>;
  otherData: Reducer<any, any>;
  balance: Reducer<any, any>;
  topup: Reducer<any, any>;
  currentChain: Reducer<any, any>;
  currentProvider: Reducer<any, any>;
}

const reducers: Reducer<RootState, any> = combineReducers({
  file: fileReducer,
  auth: authReducer,
  otherData: otherDataReducer,
  balance: balanceReducer,
  topup: topupReducer,
  currentChain: chainReducer,
  currentProvider: providerReducer,
});

export default reducers;

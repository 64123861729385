const providerReducer = (state: any = null, action: any) => {
  // action has two part action.type & action.payload
  switch (action.type) {
    case "setProvider":
      return { ...state, ...action.payload };
    case "getProvider":
      return state;
    default:
      return state;
  }
};

export default providerReducer;

interface historyInterface {
  navigate: any;
  push: (page: string, ...rest: any[]) => void;
}

const History: historyInterface = {
  navigate: () => {},
  push: (page, ...rest) => {
    if (History.navigate) {
      History.navigate(page, ...rest);
    }
  },
};

export default History;

const authReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case "login":
      return (state = action.payload);
    case "logout":
      return (state = null);
    default:
      return state;
  }
};

export default authReducer;

const topupReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case "setTopupPlan":
      return action.payload;
    case "getTopupPlan":
      return state;
    default:
      return state;
  }
};

export default topupReducer;

const balanceReducer = (state: any = {}, action: any) => {
  // action has two parts: action.type & action.payload
  switch (action.type) {
    case "setBalanceData":
      return { ...state, ...action.payload };
    case "getBalanceData":
      return state;
    default:
      return state;
  }
};

export default balanceReducer;

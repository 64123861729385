import { createStore, applyMiddleware, Store } from "redux";
import reducers from "./reducers/index";
import { thunk, ThunkMiddleware } from "redux-thunk"; // Update this line
import { StateLoader } from "../utils/services/stateLoader";
import { RootState } from "./types";
const thunkMiddleware: ThunkMiddleware<RootState, any> = thunk as unknown as ThunkMiddleware<RootState, any>;
const stateLoader = new StateLoader();
const store: Store<RootState, any> = createStore(
  reducers,
  stateLoader.loadState(),
  applyMiddleware(thunkMiddleware)
);

export default store;